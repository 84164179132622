.splitRow {

  &Active {
    background-color: var(--split-active-color);
  }

  :hover {
    background-color: var(--bs-tertiary-bg);
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-tertiary-bg);
  }
}

.liveRunContainer:hover {
  cursor: pointer;
  background-color: var(--bs-tertiary-bg) !important;
}

a > .liveRunContainer {
  text-underline: none;
}

// ToDo: Remove in the future
.timerBody {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.splitName {
  width: 250px;

  @media screen and (max-width: 1899.98px) {
    width: 220px;
  }

  @media screen and (min-width: 1199.98px) and (max-width: 1599.98px) {
    width: 185px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1599.98px) {
    width: 141px;
  }
}

.finalSplitRow {
  background-color: blue;

  &:hover {
    background-color: var(--bs-border-color);
    cursor: pointer;
  }
}

.sortButton {
  background-color: var(--bs-secondary-bg);
  width: 100%;
  height: 100%;
  border-color: var(--bs-tertiary-bg);

  &Active {
    background-color: var(--bs-tertiary-bg);
  }

  &:hover {
    background-color: var(--bs-tertiary-bg);
    border-color: var(--bs-tertiary-bg);
  }
}

.tournamentInfo {
  width: 100%;

  &Logo {
    margin-bottom: 0;
  }
}

.tournamentHowDoesThisWorkButton {
  margin-bottom: -1.5rem;
  font-size: 1.5rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1rem;
}

.tournamentTimer {
  margin-bottom: -5rem;
}

.timer {
  font-size: x-large;
}

@media screen and (max-width: 1199.98px) {

  .metadataBody {
    max-width: 320px;
  }

  .tournamentInfoLogo {
    margin-bottom: 0;
  }

  .tournamentHowDoesThisWorkButton {
    margin-bottom: 1rem;
  }

  .tournamentTimer {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 575.98px) {
  .infoBody {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .metadataBody {
    max-width: 210px;
  }
}

@media screen and (max-width: 767.98px) {

  .username {
    font-size: large;
  }
}

@media screen and (min-width: 1199.98px) {
  @media screen and (max-width: 1599.98px) {
    .infoBody {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }

    .metadataBody {
      max-width: 293px;
    }
  }

  @media screen and (max-width: 1399.98px) {
    .metadataBody {
      max-width: 250px;
    }

    .username {
      font-size: large;
    }

    .game {
      font-size: medium;
    }

    .category {
      font-size: medium;
    }

    .timer {
      font-size: large;
    }

    .delta {
      font-size: medium;
    }

    .infoBody {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
