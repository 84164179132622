.filterRow {
    margin-bottom: 1rem;
    position: absolute;
    width: 101.6%;
    padding-top: 1rem;
}

.sameGameRunHeader {
    display: none;
}

.sessionOptional {
}

.splitOptional {
}

.splitComparisonOption {
    display: none;
}


.hideSelectArrow {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 0.01px;
    text-overflow: "";
    appearance: none !important;
}

.image {
    float: left;
    min-width: 100px;
    min-height: 132px;
    margin-right: 1rem;
    margin-top: 0.5rem;
    display: flex;
    justify-self: center;
}

@media screen and (max-width: 767.98px) {

    .filterRow {
        position: relative;
        float: left;
        width: 100%;
        --bs-gutter-x: 0;
    }

    .filterMargin {
        display: none;
    }

    .sessionOptional {
        display: none;
    }

    .splitOptional {
        display: none;
    }

    .splitComparisonOption {
        display: revert;
    }

    .image {
        display: none;
    }
}
