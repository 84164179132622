fieldset.fieldset {
    padding: 0.5rem 2rem;
}

.editInfoButton {
    width: 15rem;
    height: 2.5rem;
    background-color: var(--bs-secondary-bg);
    border-color: var(--bs-link-color);
    font-weight: 0;
    color: var(--bs-body-color);
}

.editInfoButton:hover {
    background-color: var(--bs-link-color);
    border-color: var(--bs-link-color);
}

.editInfoButton:active {
    background-color: var(--bs-link-color);
    border-color: var(--bs-link-color);
}

.editInfoButton:focus {
    background-color: var(--bs-link-color);
    border-color: var(--bs-link-color);
}

.cancelButton {
    margin-left: 1rem;
    width: 5rem;
    height: 2.5rem;
    background-color: var(--bs-secondary-bg);
    border-color: darkred;
    font-weight: 0;
    color: var(--bs-body-color);
}

.cancelButton:hover {
    background-color: darkred;
    border-color: darkred;
}

.cancelButton:active {
    background-color: darkred;
    border-color: darkred;
}

.cancelButton:focus {
    background-color: darkred;
    border-color: darkred;
}
