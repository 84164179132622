.gamesNavigationContainer {
  position: relative;
}

.navigation {
  margin-bottom: 1rem;
  position: absolute;
  width: 101.6%;
  padding-top: 1rem;
}

.tabsContainer {
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}

.statsVertical {
    display: none;
}

.form {
}

.optionalColumn {
}

.tableVertical {

    tr {
        border-bottom: 1px var(--bs-table-border-color) solid;
        > *:first-child { padding-left: 0 }
        > *:last-child { padding-right: 0 }
        &:last-child { border-bottom: none}
        &:not(:first-child) .tableVerticalHeader { margin-top: 2rem}
    }
}

.tableVerticalHeader {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    text-align: center;
    border-top: 2rem solid transparent;
}

@media screen and (max-width: 767.98px) {
    .statsVertical {
        display: revert;
    }

    .navigation {
        margin: 0;
        width: 100%;
        padding: 0;
        position: revert;
    }
    .form {
        margin: 0;
        padding: 0;
    }

    .tableVertical {

        tr {
            > *:last-child:not(:only-child) {
                text-align: right
            }
        }
    }
}

@media screen and (max-width: 575.98px) {
    .optionalColumn {
        display: none;
    }
}
