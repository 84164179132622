.optionalColumn {
}

@media screen and (max-width: 767.98px) {
    .optionalColumn {
        display: none;
    }
}

.filter {
    margin-right: 1rem;
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: pointer;
    font-style: italic;
}
